<template>
	<v-menu v-bind="$attrs" v-on="$listeners">
		<slot v-for="slot in Object.keys($slots)" :v-slot="slot" :name="slot"></slot>
		<!-- Pass on all scoped slots -->
		<template v-for="slot in Object.keys($scopedSlots)" v-slot:[slot]="scope"><slot :name="slot" v-bind="scope"></slot></template>
	</v-menu>
</template>

<script>
/**
 * @displayName w-menu
 *
 * @since 0.3.2
 */
export default {
	name: 'WMenu'
}
</script>
